<script setup lang="ts">
import type {
  ComponentPopularBrandsProps,
  FragmentPopularBrand
} from "./ComponentPopularBrands.props"
const props = defineProps<ComponentPopularBrandsProps>()

const brandsMapping = computed(() => {
  return {
    title: props.title,
    tabs: {
      tabsList: props.tabs.map((tab: FragmentPopularBrand) => {
        return {
          text: tab?.title ?? "",
          content: tab.brands.map((brand) => ({
            image: brand.brandLogo,
            path: brand.slug ?? ""
          }))
        }
      })
    }
  }
})
</script>

<template>
  <div class="cms-component-popular-brands">
    <RailsPopularBrands v-bind="brandsMapping" />
  </div>
</template>
